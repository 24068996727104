import React, { Component } from "react";
import Slider from "react-slick";
import Lightbox from "react-images";
import MarkdownRenderer from "react-markdown-renderer";
import Button from "../components/button";
import Layout from "../components/layout";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import AboutBox from "../components/about_box";

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: 0,
      lightboxIsOpen: false,
      lightboxTitle: "O firmie",
      lang: this.props.pageContext.lang,
      menu: this.props.pageContext.menu,
      footerMenu: this.props.pageContext.footerMenu,
      breadcrumbs: [
        {
          name: "O firmie",
          href: "",
        },
      ],
    };
  }

  handleLightBoxOpen = currentImage => {
    this.setButtonsHeight();
    this.setState({ currentImage, lightboxIsOpen: true });
    setTimeout(() => {
      const header = document.querySelector('#lightboxBackdrop [class^="header"] span');
      if (!header) return false;
      header.innerHTML = this.state.lightboxTitle;
    }, 100);
  };

  gotoNext = (currentImage, images) => {
    this.setButtonsHeight();
    this.setState({
      currentImage: (currentImage + 1) % images.length,
    });
  };
  gotoPrevious = (currentImage, images) => {
    this.setButtonsHeight();
    this.setState({
      currentImage: (currentImage + images.length - 1) % images.length,
    });
  };
  closeLightbox = () => this.setState({ lightboxIsOpen: false });
  setButtonsHeight = () => {
    setTimeout(() => {
      const buttons = document.querySelectorAll('[class^="arrow"]');
      const images = document.querySelectorAll('[class*="imageLoaded"]');
      if (images && images.length > 0) {
        const height = images[0].height;
        buttons.map = Array.prototype.map;
        buttons.map(element => {
          element.style.height = `${height}px`;
          element.style.opacity = `1`;
          return element;
        });
      }
    }, 300);
  };

  render() {
    const { breadcrumbs, currentImage, lightboxIsOpen, lang, menu, footerMenu } = this.state,
      settingsAbout = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      images = this.props.pageContext.about.gallery_relation.map(({ image, title }) => ({ src: image, title: title }));

    const { about } = this.props.pageContext;

    return (
      <Layout menu={menu} footerMenu={footerMenu} title={about.seoTitle}>
        <Lightbox
          images={images}
          isOpen={lightboxIsOpen}
          onClickPrev={() => this.gotoPrevious(currentImage, images)}
          onClickNext={() => this.gotoNext(currentImage, images)}
          showCloseButton={true}
          onClose={this.closeLightbox}
          currentImage={currentImage}
          backdropClosesModal={true}
        />
        <div className="bg-gradient">
          <Header lang={lang} />
          <Breadcrumbs data={breadcrumbs} lang={lang} />
          <div className="main-wrapper-md">
            <section className="about-container red-line">
              <header className="text-holder">
                <h1>
                  O <strong>firmie</strong>
                </h1>
                <p>{about.main_description}</p>
              </header>
              <div className="about-slider">
                <Slider {...settingsAbout}>
                  {images.map(({ src, title }, index) => (
                    <AboutBox img={src} key={index} title={title} lightboxOpen={() => this.handleLightBoxOpen(index)} />
                  ))}
                </Slider>
              </div>
            </section>
          </div>
        </div>
        <section className="about-atuts bg-gradient">
          <div className="main-wrapper-md">
            <header className="section-header red-line">
              <h2>
                Nasze <strong>atuty</strong>
              </h2>
            </header>
            <div className="atuts-box-wrapper">
              <article className="atut-box">
                <div className="box-image">
                  <img src={require("../images/atuts-exp.svg")} alt="" />
                </div>
                <div className="box-text">
                  <h3>{about.experience_title}</h3>
                  <p>{about.experience_description}</p>
                </div>
              </article>
              <article className="atut-box">
                <div className="box-image">
                  <img src={require("../images/atuts-del.svg")} alt="" />
                </div>
                <div className="box-text">
                  <h3>{about.delivers_title}</h3>
                  <p>{about.delivers_description}</p>
                </div>
              </article>
              <article className="atut-box">
                <div className="box-image">
                  <img src={require("../images/atuts-best.svg")} alt="" />
                </div>
                <div className="box-text">
                  <h3>{about.solidity_title}</h3>
                  <p>{about.solidity_description}</p>
                </div>
              </article>
              <article className="atut-box">
                <div className="box-image">
                  <img src={require("../images/atuts-mach.svg")} alt="" />
                </div>
                <div className="box-text">
                  <h3>{about.machines_title}</h3>
                  <p>{about.machines_description}</p>
                </div>
              </article>
            </div>
          </div>
        </section>
        <section className="about-offer bg-gradient">
          <div className="main-wrapper-md">
            <header className="section-header red-line">
              <MarkdownRenderer markdown={about.offer_title} />
            </header>
            <MarkdownRenderer markdown={about.offer_description} />
            <a className="btn-red" href={about.table_file} target="_blank"><span>{about.table_file_text}</span></a>
          </div>
        </section>
        <section className="about-map">
          <div className="main-wrapper-md">
            <div className="map-wrapper">
              <article className="text-holder">
                <header className="section-header red-line line-md">
                  <MarkdownRenderer markdown={about.realisations_title} />
                </header>
                <p>{about.realisations_description}</p>
                <Button text="Zobacz wszystkie realizacje" href="/pl/realizacje" />
              </article>
            </div>
          </div>
        </section>
        <section className="about-go-forward bg-gradient">
          <div className="main-wrapper-md">
            <header className="section-header red-line">
              <MarkdownRenderer markdown={about.evolution_title} />
            </header>
            <MarkdownRenderer markdown={about.evolution_description} />
          </div>
        </section>
      </Layout>
    );
  }
}

export default About;
