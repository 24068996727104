import React from "react";

const AboutBox = ({ img, title, lightboxOpen }) => {
  return (
    <div className="about-box">
      <div className="title-box">
        <h4 className="title">{title}</h4>
      </div>
      <div className="image-box" style={{backgroundImage: `url(${img})`}}>
      </div>
      <button className="btn-zoom" type="button" onClick={() => lightboxOpen()}><img src={require('../images/zoom-icon.svg')} alt=""/></button>
    </div>
  );
};

export default AboutBox;
